/*
 * @Descripttion:
 * @version:
 * @Author: king
 * @Date: 2023-11-06 11:26:46
 * @LastEditors: wei
 * @LastEditTime: 2023-11-15 15:13:06
 */
import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import createPersistedState from 'vuex-persistedstate';
import { get, post } from '@/assets/js/http';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: '',
    avatar: '',
    username: '',
    buttons: [],
    menu: [],
    userInfo: {},
    type: 0,
  },
  getters: {
    risk_level: () => {
      //1.A级风险2.B级风险3.C级风险
      return {
        0:{
          label: '无风险',
          value: 0,
        },  
        1: {
          label: 'A级风险',
          value: 1,
          type:'danger'
        },
        2: {
          label: 'B级风险',
          value: 2,
          type:'warning'
        },
        3: {
          label: 'C级风险',
          value: 3,
          type:'info'
        },
      }
    }
  },
  mutations: {
    login(state, payload) {
      state.token = payload.token;
    },
    logout(state) {
      state.token = '';
      state.type = 0;
      state.menu = [];
      window.location.href =
        '/#' +
        '/login?type=' +
        (this.type == 1
          ? ''
          : this.type == 2
            ? 'company'
            : this.type == 6
              ? 'masses'
              : '');
    },
    setAdminInfo(state, payload) {
      state.avatar = payload.avatar;
      state.username = payload.username;
    },
    setButtons(state, payload) {
      state.buttons = payload.filter((item) => item.name != 'hidden');
    },
    SET_USERINFO(state, payload) {
      state.userInfo = payload;
      console.log(state.userInfo);
    },
    SET_MENU(state, payload) {
      state.menu = payload;
    },
    setType(state, type) {
      state.type = type;
    },
  },
  actions: {
    setMenu(ctx, payload) {
      ctx.commit('SET_MENU', payload);
    },
    setType(ctx, type) {
      ctx.commit('setType', type);
    },
    setAdminInfo(ctx, userInfo) {
      ctx.commit('setAdminInfo', userInfo);
    },
    set_userInfo(ctx) {
      post({
        url: '/admin/Index/admin_info',
      }).then((res) => {
        ctx.commit('SET_USERINFO', res);
        ctx.commit('setAdminInfo', res);
      });
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});

