<template>
  <el-container>
    <!--        <el-aside width="200px" :class="{ 'el-aside&#45;&#45;collapse': isCollapse }">-->
    <!--            <el-menu-->
    <!--                :default-active="defaultActive"-->
    <!--                menu-trigger="click"-->
    <!--            >-->
    <!--                <template v-for="lev1 in menuData">-->
    <!--                    <el-submenu-->
    <!--                        v-if="lev1.type === 'menu_dir'"-->
    <!--                        :index="lev1.name"-->
    <!--                        :key="lev1.id"-->
    <!--                    >-->
    <!--                        <template slot="title">-->
    <!--                            <i :class="lev1.icon"></i>-->
    <!--                            <span>{{ lev1.title }}</span>-->
    <!--                        </template>-->
    <!--                        <template v-for="lev2 in lev1.children">-->
    <!--                            <el-submenu-->
    <!--                                v-if="lev2.type === 'menu_dir'"-->
    <!--                                :index="lev2.name"-->
    <!--                                :key="lev2.id"-->
    <!--                            >-->
    <!--                                <template slot="title">-->
    <!--                                    <i :class="lev2.icon"></i>-->
    <!--                                    <span>{{ lev2.title }}</span>-->
    <!--                                </template>-->
    <!--                                <template v-for="lev3 in lev2.children">-->
    <!--                                    <el-menu-item-->
    <!--                                        v-if="lev3.type === 'menu'"-->
    <!--                                        :key="lev3.id"-->
    <!--                                        :index="lev3.path"-->
    <!--                                        @click="onSelect(lev3)"-->
    <!--                                    >-->
    <!--                                        <i :class="lev3.icon"></i>-->
    <!--                                        <span slot="title">{{-->
    <!--                                            lev3.title-->
    <!--                                        }}</span>-->
    <!--                                    </el-menu-item>-->
    <!--                                </template>-->
    <!--                            </el-submenu>-->
    <!--                            <el-menu-item-->
    <!--                                v-else-if="lev2.type === 'menu'"-->
    <!--                                :key="lev2.id"-->
    <!--                                :index="lev2.path"-->
    <!--                                @click="onSelect(lev2)"-->
    <!--                            >-->
    <!--                                <i :class="lev2.icon"></i>-->
    <!--                                <span slot="title">{{ lev2.title }}</span>-->
    <!--                            </el-menu-item>-->
    <!--                        </template>-->
    <!--                    </el-submenu>-->
    <!--                    <el-menu-item-->
    <!--                        v-else-if="lev1.type === 'menu'"-->
    <!--                        :key="lev1.id"-->
    <!--                        :index="lev1.path"-->
    <!--                        @click="onSelect(lev1)"-->
    <!--                    >-->
    <!--                        <i :class="lev1.icon"></i>-->
    <!--                        <span slot="title">{{ lev1.title }}</span>-->
    <!--                    </el-menu-item>-->
    <!--                </template>-->
    <!--            </el-menu>-->
    <!--        </el-aside>-->
    <el-container>
      <el-header
        class="flex ali-c jus-b"
        height="50px"
        style="position: relative; padding: 0 24px; font-size: 15px"
      >
        <div>
          {{
            $route.query.type
              ? $getRouteAuth($route.fullPath).currentAuth.title
              : $getRouteAuth().currentAuth.title
          }}
        </div>
        <div
          style="
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 18px;
          "
        >
          {{ title }}
        </div>
        <el-link @click="$router.push('/')" style="color: white">
          首页
        </el-link>

        <el-row
          v-if="auth([6])"
          align="middle"
          justify="space-between"
          type="flex"
        >
          <el-tooltip content="进入全屏">
            <div
              v-show="!isFullscreen"
              class="header-fullscreen"
              @click="startFullscreen"
            >
              <i class="fa fa-expand" style="color: white"></i>
            </div>
          </el-tooltip>
          <el-tooltip content="退出全屏">
            <div
              v-show="isFullscreen"
              class="header-fullscreen"
              @click="stopFullscreen"
            >
              <i class="fa fa-compress" style="color: white"></i>
            </div>
          </el-tooltip>
          <el-link @click="$router.push('/')" style="color: white">
            首页
          </el-link>
          <div v-if="base_list.length" class="ml-20" style="margin-top: 3px">
            <el-dropdown>
              <p class="flex ali-c cusor-pointer" style="color: white">
                <img
                  style="height: 16px; margin-right: 4px"
                  src="@/assets/image/4.webp"
                  alt=""
                />基础信息
              </p>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  @click.native="$router.push(item.path)"
                  v-for="(item, index) in base_list"
                  :key="`nav-${index}`"
                  >{{ item.title }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="cusor-pointer ml-20">
            <el-dropdown>
              <p class="flex ali-c cusor-pointer" style="color: white">
                <img
                  style="height: 16px; margin-right: 4px"
                  src="@/assets/image/3.webp"
                  alt=""
                />{{ $store.state?.username }}
              </p>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="logout"
                  >退出登录</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-row>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { post } from '@/assets/js/http.js';
import { getRouteAuth } from '@/assets/js/auth';

function pad(value) {
  return value < 10 ? '0' + value : value;
}

export default {
  name: 'view-index',
  computed: {
    getRouteAuth() {
      return getRouteAuth;
    },
    title() {
      let type = this.$store.state.type;
      return type == 1
        ? '管理后台'
        : type == 2
        ? '射洪三农管理平台'
        : type == 6
        ? '射洪三农管理平台'
        : '';
    },
  },
  data() {
    return {
      date: '',
      menuData: [],
      isCollapse: false,
      defaultActive: '',
      isFullscreen: false,
      base_list: [],
      sys_list: [],
    };
  },
  mounted() {
    this.updateDate();
    // 监听全屏事件
    document.body.addEventListener('fullscreenchange', this.onFullscreenChange);
    this.$root.$on('update-menu', () => {
      this.getMenuData();
    });
    this.getMenuData();

    this.buttonList = this.$store.state.menu;
    this.base_list =
      this.$store.state.menu.find((item) => item.path == 'base')?.children ||
      [];
    this.sys_list =
      this.$store.state.menu
        .find((item) => item.path == 'sys')
        ?.children?.map((item) => {
          if (item.children && item.type == 'menu_dir') {
            return item.children;
          } else {
            return item;
          }
        })
        .flat(2) || [];
  },
  beforeDestroy() {
    // 移除全屏事件
    document.body.removeEventListener(
      'fullscreenchange',
      this.onFullscreenChange
    );
  },
  watch: {
    $route() {
      this.updateDefaultActive();
    },
  },
  methods: {
    updateDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const h = date.getHours();
      const m = date.getMinutes();
      const s = date.getSeconds();
      this.date = `${year}-${pad(month)}-${pad(day)} ${pad(h)}:${pad(m)}:${pad(
        s
      )}`;
      setTimeout(() => {
        this.updateDate();
      }, 1000);
    },
    toArchive() {
      this.$router.push('/archive');
    },
    onFullscreenChange() {
      this.isFullscreen = !this.isFullscreen;
    },
    async getMenuData() {
      const data = await post({
        url: '/admin/index/index',
        loading: true,
      });
      this.menuData = data.menus;
      this.$store.commit('setAdminInfo', data.adminInfo);
      this.$store.dispatch('setMenu', data.menus);
      this.updateDefaultActive();
    },
    updateDefaultActive() {
      this.$nextTick(() => {
        const pathSplit = this.$route.path.split('/');
        this.defaultActive = '/' + pathSplit[1] || '';
      });
    },
    logout() {
      console.log('loutotu');
      this.$store.commit('logout');
    },
    startFullscreen() {
      try {
        document.body.requestFullscreen();
      } catch (err) {
        this.$message.error('切换全屏失败');
      }
    },
    stopFullscreen() {
      document.exitFullscreen();
    },
    onLogout() {
      this.$confirm('确认退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'default',
      })
        .then(async () => {
          await post({
            url: '/admin/index/logout',
            data: {},
            loading: true,
          });
          this.$message.success('退出登录成功');
          this.$store.commit('logout');
          this.$router.push(
            '/login?type=' +
              (this.role == 1
                ? ''
                : this.role == 2
                ? 'company'
                : this.role == 6
                ? 'masses'
                : '')
          );
        })
        .catch(() => {});
    },
    onSelect(item) {
      if (Array.isArray(item.children)) {
        const visit = item.children.find((item) => item.path === 'visit');
        if (visit) {
          this.$store.commit(
            'setButtons',
            item.children.filter((item) => item.path !== 'visit')
          );
          this.$router.push(item.path);
        } else {
          this.$message.error('您没有该页面访问权限');
          this.updateDefaultActive();
        }
      } else {
        this.$message.error('您没有该页面访问权限');
        this.updateDefaultActive();
      }
    },
  },
};
</script>
<style>
.el-header {
  background: linear-gradient(
    to bottom,
    rgba(27, 76, 179, 0.92),
    rgba(0, 79, 196, 0.92)
  );
  color: white;
}
</style>
