<template>
	<section id="app">
		<header id="header">
            <el-button @click="$router.back()" type="primary" style="margin-top: 20px;margin-left: 20px;">返回</el-button>
			<div class="header__date-wrapper">
				{{ date }}
			</div>
			<h1 class="header__title-wrapper">数智农安—信用</h1>
			<div class="header__empty-wrapper">
				<img v-if="isFullscreen" @click="stopFullscreen" src="~@/assets/image/37.png" alt="" />
				<img v-else-if="!isFullscreen" @click="startFullscreen" src="~@/assets/image/36.png" alt="" />
			</div>
			<div class="header__user-wrapper">
				{{ $store.state.username }}
				<div class="header__downdrop-wrapper">
					<!-- <z-triangle-wrapper shadow-size="sm">
                        <div class="header__downdrop-list">
                            <div
                                class="header__downdrop-item"
                                @click="onLogout"
                            >
                                <i class="el-icon-switch-button"></i>
                                <span class="header__downdrop-text"
                                    >退出登录</span
                                >
                            </div>
                        </div>
                    </z-triangle-wrapper> -->
				</div>
			</div>
		</header>
		<section id="body">
			<!-- <aside id="aside">
                <z-nav-menu></z-nav-menu>
            </aside> -->
			<main id="main">
				<router-view></router-view>
			</main>
		</section>
	</section>
</template>

<script>
import { post } from "@/assets/js/http.js"

function pad(value) {
	return value < 10 ? "0" + value : value
}

export default {
	name: "view-Sreen",
	data() {
		return {
			date: "",
		}
	},
	mounted() {
		// 监听全屏事件
		document.body.addEventListener("fullscreenchange", this.onFullscreenChange)
		this.updateDate()
	},
	beforeDestroy() {
		// 移除全屏事件
		document.body.removeEventListener("fullscreenchange", this.onFullscreenChange)
	},
	computed: {
		path() {
			return this.$route.path
		},
		isFullscreen() {
			return this.$store.state.isFullscreen
		},
	},
	methods: {
		onFullscreenChange() {
			this.$store.commit("toggleFullscreen", document.fullscreen)
		},
		startFullscreen() {
			try {
				document.body.requestFullscreen()
			} catch (err) {
				this.$message.error("切换全屏失败")
			}
		},
		stopFullscreen() {
			document.exitFullscreen()
		},
		updateDate() {
			const date = new Date()
			const year = date.getFullYear()
			const month = date.getMonth() + 1
			const day = date.getDate()
			const h = date.getHours()
			const m = date.getMinutes()
			const s = date.getSeconds()
			this.date = `${year}-${pad(month)}-${pad(day)} ${pad(h)}:${pad(m)}:${pad(s)}`
			setTimeout(() => {
				this.updateDate()
			}, 1000)
		},
		onLogout() {
			this.$confirm("确认退出登录?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "default",
			})
				.then(async () => {
					await post({
						url: "/admin/index/logout",
						data: {},
						loading: true,
					})
					this.$message.success("退出登录成功")
					this.$store.commit("logout")
					this.$router.replace("/login")
				})
				.catch(() => {})
		},
	},
}
</script>

<style scoped>
/* 布局类 */
#app {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-image: url("~@/assets/img/01.png");
	background-size: 100% 100%;
	overflow: hidden;
}
</style>
