import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Index from '../views/index.vue';
import Login from '../views/login.vue';
import Sreen from '../views/screen.vue';
import {
	Message
} from 'element-ui';

Vue.use(VueRouter);
// const VueRouterPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(to) {
//   return VueRouterPush.call(this, to).catch((err) => err);
// };

const routes = [{
	path: '/',
	name: 'view-index',
	component: Index,
	children: [{
		path: '/',
		name: 'view-dashboard',
		redirect: '/big-cate',
	},
	{
		path: '/dashboard',
		name: 'view-dashboard',
		component: () => import('../views/dashboard/index.vue'),
	},
	{
		path: '/menu',
		name: 'view-menu',
		component: () => import('../views/menu/index.vue'),
	},

	{
		path: '/admin',
		name: 'view-admin',
		component: () => import('../views/admin/index.vue'),
	},
	{
		path: '/role',
		name: 'view-role',
		component: () => import('../views/role/index.vue'),
	},
	//客户管理列表
	{
		path: '/member',
		name: 'view-member',
		component: () => import('../views/member/index.vue'),
	},
	{
		path: '/admin-log',
		name: 'view-admin-log',
		component: () => import('../views/admin-log/index.vue'),
	},
	{
		path: '/system-config',
		name: 'view-system-config',
		component: () => import('../views/system-config/index.vue'),
	},
	{
		path: '/company',
		name: 'company',
		component: () => import('../views/company/index.vue'),
	},
	{
		path: '/company/add',
		name: 'company-add',
		component: () => import('../views/company/add.vue'),
	},
	{
		path: '/company-cate',
		name: 'company-cate',
		component: () => import('../views/company-cate/index.vue'),
	},
	{
		path: '/town',
		name: 'town',
		component: () => import('../views/town/index.vue'),
	},
	{
		path: '/group',
		name: 'group',
		component: () => import('../views/group/index.vue'),
	},
	{
		path: '/cadre',
		name: 'cadre',
		component: () => import('../views/cadre/index.vue'),
	},
	{
		path: '/supervisor',
		name: 'supervisor',
		component: () => import('../views/supervisor/index.vue'),
	},
	{
		path: '/appeal',
		name: 'appeal',
		component: () => import('../views/appeal/index.vue'),
	},

	{
		path: '/masses',
		name: 'masses',
		component: () => import('../views/masses/index.vue'),
	},
	{
		path: '/complain',
		name: 'complain',
		component: () => import('../views/complain/index.vue'),
	},
	{
		path: '/message',
		name: 'message',
		component: () => import('../views/message/index.vue'),
	},
	{
		path: '/companydepartment',
		name: 'companydepartment',
		component: () => import('../views/companydepartment/index.vue'),
	},
	{
		path: '/company-cate-charts',
		name: 'company-cate-charts',
		component: () => import('../views/company-cate-charts/index.vue'),
	},
	{
		path: '/cate-to-company',
		name: 'cate-to-company',
		component: () => import('../views/cate-to-company/index.vue'),
	},

	{
		path: '/worklist',
		name: 'article-worklist',
		component: () => import('../views/worklist/index.vue'),
	},
	{
		path: '/community',
		name: 'article-community',
		component: () => import('../views/community/index.vue'),
	},
	{
		path: '/patrol',
		name: 'article-patrol',
		component: () => import('../views/patrol/index.vue'),
	},
	{
		path: '/assignment',
		name: 'article-assignment',
		component: () => import('../views/assignment/index.vue'),
	},
	{
		path: '/sign',
		name: 'article-sign',
		component: () => import('../views/sign/index.vue'),
	},
	{
		path: '/feedback',
		name: 'article-feedback',
		component: () => import('../views/feedback/index.vue'),
	},
	{
		path: '/banner',
		name: 'article-banner',
		component: () => import('../views/banner/index.vue'),
	},

	// 学历列表
	{
		path: '/education',
		name: 'education',
		component: () => import('../views/education/index.vue'),
	},

	// 主体类型
	{
		path: '/maintype',
		name: 'maintype',
		component: () => import('../views/maintype/index.vue'),
	},
	// 巡查记录
	{
		path: '/supervise',
		name: 'supervise',
		component: () => import('../views/supervise/index.vue'),
	},
	// 我的信息
	{
		path: '/userInfo',
		name: 'userInfo',
		component: () => import('../views/userInfo/index.vue'),
	},
	// 等级列表
	{
		path: '/grade',
		name: 'grade',
		component: () => import('../views/grade/index.vue'),
	},

	// 积分榜
	{
		path: '/ranking',
		name: 'ranking',
		component: () => import('../views/ranking/index.vue'),
	},
	
	//用户管理
	{
		path: '/ranking',
		name: 'ranking',
		component: () => import('../views/ranking/index.vue'),
		// redirect: "/userlist",
		children: [
			//年度红黑榜

			{
				path: '/yearRanking',
				name: 'yearRanking',
				component: () => import('../views/ranking/yearRanking/index.vue'),
			},
			//季度红黑榜
			{
				path: '/seasonsRanking',
				name: 'seasonsRanking',
				component: () =>
					import('../views/ranking/seasonsRanking/index.vue'),
			},
			//月度度红黑榜
			{
				path: '/monthRanking',
				name: 'monthRanking',
				component: () => import('../views/ranking/monthRanking/index.vue'),
			},
		],
	},

	//用户管理
	{
		path: '/userManager',
		name: 'userManager',
		component: () => import('../views/userManager/index.vue'),
		// redirect: "/userlist",
		children: [
			//乡镇人员
			{
				path: '/userlist',
				name: 'userlist',
				component: () => import('../views/userlist/index.vue'),
			},
			//村人员
			{
				path: '/villageList',
				name: 'villageList',
				component: () => import('../views/villageList/index.vue'),
			},
			//生产主体人员
			{
				path: '/productMain',
				name: 'productMain',
				component: () => import('../views/productMain/index.vue'),
			},
			{
				path: '/citynumber',
				name: 'citynumber',
				component: () => import('../views/citynumber/index.vue'),
			},
			{
				path: '/country',
				name: "country",
				component: () => import('../views/country/index.vue'),
			},
		],
	},

	//政策动态
	{
		path: '/articleManager',
		name: 'articleManager',
		component: () => import('../views/articleManager/index.vue'),
		// redirect: "/userlist",
		children: [
			//政策动态

			{
				path: '/article',
				name: 'article',
				component: () => import('../views/article/index.vue'),
			},
			{
				path: '/article-type',
				name: 'article-type',
				component: () => import('../views/article-type/index.vue'),
			},
		],
	},
	// 题目管理
	{
		path: '/topic',
		name: 'topic',
		component: () => import('../views/topic/index.vue'),
	},

	//生产记事
	{
		path: '/productEvents',
		name: 'productEvents',
		component: () => import('../views/productEvents/index.vue'),
		// redirect: '/product',
		children: [
			// 肥料使用记录
			{
				path: '/fertilizerLog',
				name: 'fertilizerLog',
				component: () => import('../views/fertilizerLog/index.vue'),
			},
			// 肥料购买及出入库记录
			{
				path: '/fertilizer',
				name: 'fertilizer',
				component: () => import('../views/fertilizer/index.vue'),
			},
			// 生产农事记录
			{
				path: '/farming',
				name: 'farming',
				component: () => import('../views/farming/index.vue'),
			},
			// 农药使用记录
			{
				path: '/pesticide',
				name: 'pesticide',
				component: () => import('../views/pesticide/index.vue'),
			},
			// 产品收货，销售记录
			{
				path: '/product',
				name: 'product',
				component: () => import('../views/product/index.vue'),
			},
			// 农药购买及出入库记录
			{
				path: '/pesticideLog',
				name: 'pesticideLog',
				component: () => import('../views/pesticideLog/index.vue'),
			},

		],
	},

	],
},
{
	path: '/login',
	name: 'view-login',
	component: Login,
},
{
	path: '/sreen',
	name: 'Sreen',
	component: Sreen,
	// redirect: "/userlist",
	children: [
		//政策动态
		{
			path: '/sreen',
			name: 'sreen',
			component: () => import('../views/sreen/index.vue'),
		},
	],
},
{
	path: '/big-cate',
	name: 'big-cate',
	component: () => import('../views/big-cate/index.vue'),
},

{
	path: '*',
	name: 'view-404',
	component: () =>
		import( /* webpackChunkName: 'view-404' */ '../views/404.vue'),
},
];

const router = new VueRouter({
	routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.path === '/rfm-detail') {
//     if (
//       store.state.buttons.findIndex((item) => item.path === 'rfm-detail') >= 0
//     ) {
//       next();
//     } else {
//       Message.error('您没有该页面访问权限');
//     }
//   } else if (to.path === '/rfm-setup') {
//     if (
//       store.state.buttons.findIndex((item) => item.path === 'rfm-setup') >= 0
//     ) {
//       next();
//     } else {
//       Message.error('您没有该页面访问权限');
//     }
//   } else {
//     if (to.path !== '/login' && !store.state.token) {
//       next(
//         '/login?type=' +
//           (store.state.type == 1
//             ? ''
//             : store.state.type == 2
//             ? 'company'
//             : store.state.type == 6
//             ? 'masses'
//             : '')
//       );
//     } else {
//       next();
//     }
//   }
// });

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

export default router;