<template>
  <div class="login-page">
    <div class="login_imgbox">
      <div class="login_img">
        <img
          src="../assets/image/19.png"
          style="width: 100%; max-width: 700px"
          alt=""
        />
      </div>
      <div class="login-container">
        <div class="login-title">射洪三农管理平台</div>
        <!-- <div class="login-title" v-if="$route.query.type=='masses'">军屯镇派出所管理平台</div> -->
        <div class="login-field">
          <i
            class="el-icon-s-custom"
            style="color: #2e67fc; font-size: 30px; margin-left: 20px"
          ></i>
          <input
            v-model="username"
            class="login-field-value"
            placeholder="请输入用户名"
            autocomplete="new-password"
            type="text"
          />
        </div>
        <div class="login-field">
          <i
            class="el-icon-lock"
            style="color: #2e67fc; font-size: 30px; margin-left: 20px"
          ></i>
          <input
            v-model="password"
            class="login-field-value"
            placeholder="请输入密码"
            autocomplete="new-password"
            type="password"
          />
        </div>
        <div class="login-button" @click="onLogin">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from '@/assets/js/http.js';

export default {
  name: 'view-login',
  data() {
    return {
      save: false,
      username: '',
      password: '',
    };
  },
  mounted() {
    if (this.save) {
      this.username = localStorage.getItem('username');
      this.password = localStorage.getItem('password');
    } else {
      this.username = '';
      this.password = '';
    }
  },
  methods: {
    async onLogin() {
      if (this.username && this.password) {
        localStorage.setItem('save', this.save);
        if (this.save) {
          localStorage.setItem('username', this.username);
          localStorage.setItem('password', this.password);
        } else {
          localStorage.removeItem('username');
          localStorage.removeItem('password');
        }
        const data = await post({
          url: '/admin/index/login',
          loading: true,
          successTip: true,
          data: {
            username: this.username,
            password: this.password,
          },
        });
        localStorage.setItem('role', data.rule_id);
        this.$store.commit('login', data.userinfo);
        this.$store.dispatch('setType', data.rule_id);

        const res = await post({
          url: '/admin/index/index',
          loading: true,
        });
        this.$store.dispatch('setMenu', res.menus);
        this.$store.dispatch('setAdminInfo', res.adminInfo);
        // this.$router.replace("/");
        window.location.href = '/';
      } else {
        this.$message.error('用户名和密码不能为空');
      }
    },
    tologon() {
      this.$router.push('/logon');
      // this.$router.push("/supplier");
    },
  },
};
</script>
