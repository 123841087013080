<!--
 * @Descripttion: 
 * @version: 
 * @Author: king
 * @Date: 2023-11-06 11:26:46
 * @LastEditors: wei
 * @LastEditTime: 2023-11-16 10:35:27
-->
<template>
  <router-view></router-view>
</template>
<script>
export default {
  created() {
    this.setType();
  },
  watch: {
    $route: function () {
      this.setType();
    },
  },
  methods: {
    setType() {
      if (this.$store.state.type) {
        if (this.$store.state.type == 1) {
          document.title = '射洪三农管理平台';
        }
        if (this.$store.state.type == 2) {
          document.title = '射洪三农管理平台';
        }
        if (this.$store.state.type == 6) {
          document.title = '射洪三农管理平台';
        }
      }
    },
  },
};
</script>
<style src="@/assets/css/element-m.css"></style>
<style src="@/assets/css/app.css"></style>
<style>
.el-message {
  z-index: 3002 !important;
}
.el-page-header__content {
  font-size: 15px;
}
.el-table th.el-table__cell {
  user-select: initial !important;
}
</style>
